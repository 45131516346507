<template>
  <div>
    <object :data="pdfUrl" type="application/pdf" width="100%" height="900px" style="border: none">
      <!-- オブジェクトタグ内にフォールバックコンテンツを提供 -->
      <p style="margin-top: 30px">
        SukiSukiらいぶすてーしょんの資料は
        <a :href="pdfUrl">こちら</a>からダウンロードしてください。
      </p>
    </object>
  </div>
</template>

<script setup>
import { ref } from "vue";

const pdfUrl = ref(
  "https://firebasestorage.googleapis.com/v0/b/rf-prd-sukisuki-ls.appspot.com/o/live_station_images_common%2FSukiSuki%E3%82%89%E3%81%84%E3%81%B5%E3%82%99%E3%81%99%E3%81%A6%E3%83%BC%E3%81%97%E3%82%87%E3%82%93%E3%81%93%E3%82%99%E8%AA%AC%E6%98%8E%E8%B3%87%E6%96%99_i1.pdf?alt=media&token=b25c48b9-466f-476e-a14d-ec7215f69124"
);
</script>
